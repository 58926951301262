import { createSlice } from "@reduxjs/toolkit";
import { getUserNotifications } from "../../apis/Notification/NotificationApi";
import { update } from "react-spring";

const initialState = {
  notificationCount: 0,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotificationCount(state, action) {
      state.notificationCount = action.payload;
    },
    updateNotificationCount(state, action) {
      state.notificationCount = action.payload;
    },
  },
});

export const { setNotificationCount, updateNotificationCount } =
  notificationSlice.actions;
export default notificationSlice.reducer;

export const fetchNotificationCount = () => async (dispatch) => {
  try {
    const response = await getUserNotifications({ read: false });
    const notifications = response.data?.data?.rows || [];
    dispatch(
      setNotificationCount(
        notifications.filter((notification) => notification?.is_read === 0)
          .length
      )
    );
  } catch (error) {
    console.error("Failed to fetch notifications:", error.message || error);
  }
};

export const getNotificationCount = (state) =>
  state.notification.notificationCount;
