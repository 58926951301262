import { Image } from "@mui/icons-material";
import React from "react";
import { useSelector } from "react-redux";

const Loader = () => {
  const isLoading = useSelector((state) => state.loader.isLoading);

  return (
    isLoading && (
      // <div className="loader">
      //   <div className="dot"></div>
      //   <div className="dot"></div>
      //   <div className="dot"></div>
      //   <div className="dot"></div>
      // </div>
      <div className="loader">
        <img
        // src={require("../../assets/img/logo/ease-hand.mp4")}
        src={require("../../assets/img/logo/eys-loader.gif")}
        alt="logo"
        width="278px"
        height="auto"
        // autoPlay
        // loop
        // muted
      />
    </div>
    )
  );
};

export default Loader;
