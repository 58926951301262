// third-party
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// project import
import chat from "./chat";
import calendar from "./calendar";
import menu from "./menu";
import snackbar from "./snackbar";
import productReducer from "./product";
import cartReducer from "./cart";
import kanban from "./kanban";
import invoice from "./invoice";
import loader from "./loaderSlice";
import user from "./user";
import helpVideo from "./help-video";
import reportStatusReducer from "./reportSlice";
import parentLayout from "./parent-layout";
import studentLayout from "./student-layout";
import notification from "./notification";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  chat,
  calendar,
  menu,
  snackbar,
  cart: persistReducer(
    {
      key: "cart",
      storage,
      keyPrefix: "iLoma-",
    },
    cartReducer
  ),
  product: productReducer,
  kanban,
  invoice,
  user,
  video: helpVideo,
  reportStatus: reportStatusReducer,
  parentLayout: parentLayout,
  studentLayout: studentLayout,
  loader: loader,
  notification: notification,
});

export default reducers;
