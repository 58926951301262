import { postMethod } from "../ApiConfig";
import { API_URL } from "../../config/config";

export const sendOtpApi = async (data) => {
  return postMethod(`${API_URL}/auth/send-otp`, data);
};
export const verifyOtpApi = async (data) => {
  return postMethod(`/auth/verify-otp`, data);
};

export const registerUserApi = async (data) => {
  return postMethod(`/auth/register-user`, data);
};

export const userLoginWithPhone = async (data) => {
  return postMethod(`/auth/user-login-with-phone`, data);
};

export const getProfileApi = async (data) => {
  return postMethod(`/profile/get-user-profile-details`, data);
};

export const newsletterSubscribeApi = async (data) => {
    return postMethod(`/auth/add-newsletter-sub`, data);
};